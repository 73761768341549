import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone, faMapMarkerAlt, faClock } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-contact">
          <p>
            <FontAwesomeIcon icon={faPhone} /> +92 335 222 3355
          </p>
          <p>
            <FontAwesomeIcon icon={faEnvelope} /> info@greennsolar.com
          </p>
        </div>
        <div className="footer-address">
          <p>
            <FontAwesomeIcon icon={faMapMarkerAlt} /> 31/15 A Block Canal City, Lahore
          </p>
          <p>
            <FontAwesomeIcon icon={faClock} /> Open 24 hours
          </p>
        </div>
        <div className="footer-social">
          <a href="https://www.facebook.com/profile.php?id=100091609681406&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} size="2x" style={{ color: '#3b5998' }} />
          </a>
          <a href="http://www.instagram.com/greennsolar" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} size="2x" style={{ color: '#E4405F' }} />
          </a>
          <a href="https://wa.me/+923356166279" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faWhatsapp} size="2x" style={{ color: '#25D366' }} />
          </a>
        </div>
      </div>
      <div className="footer-trademark">
        <a href="https://wa.me/+923224778575" target="_blank" rel="noopener noreferrer" className="footer-trademark-link">
          <FontAwesomeIcon icon={faWhatsapp} />
          <p>Interested in a website? Contact via WhatsApp!</p>
        </a>
      </div>
    </footer>
  );
}

export default Footer;
