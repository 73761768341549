import React from "react";
import Slider from "react-slick";
import "./Brands.css";

// Import images
import panel1 from "../Images/Brands/Panels/panels (1).webp";
import panel2 from "../Images/Brands/Panels/panels (2).webp";
import panel3 from "../Images/Brands/Panels/panels (3).webp";
import panel4 from "../Images/Brands/Panels/panels (4).webp";
import panel5 from "../Images/Brands/Panels/panels (5).webp";

import inverter1 from "../Images/Brands/Inverters/brand1.webp";
import inverter2 from "../Images/Brands/Inverters/brand2.webp";
import inverter3 from "../Images/Brands/Inverters/brand3.webp";
import inverter4 from "../Images/Brands/Inverters/brand4.webp";
import inverter5 from "../Images/Brands/Inverters/brand5.webp";
import inverter6 from "../Images/Brands/Inverters/brand6.webp";
import inverter7 from "../Images/Brands/Inverters/brand7.webp";
import inverter8 from "../Images/Brands/Inverters/brand8.webp";
import inverter9 from "../Images/Brands/Inverters/brand9.webp";

const Brands = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 2000, // Adjust speed of transition
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: false,
    centerPadding: "0",
    autoplay: true,
    autoplaySpeed: 0, // Set to 0 for continuous movement
    cssEase: "linear", // Smooth continuous scrolling
    pauseOnHover: false,
    draggable: true, // Enable dragging
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="brands-container">
      <h2 className="brands-heading">Brands We Use</h2>
      <div className="brands-slider-container">
        <h3 className="brands-slider-subheading">Solar Panels</h3>
        <div className="brands-slider">
          <Slider {...settings}>
            <div>
              <img src={panel1} alt="Brand 1" className="brands-logo" />
            </div>
            <div>
              <img src={panel2} alt="Brand 2" className="brands-logo" />
            </div>
            <div>
              <img src={panel3} alt="Brand 3" className="brands-logo" />
            </div>
            <div>
              <img src={panel4} alt="Brand 4" className="brands-logo" />
            </div>
            <div>
              <img src={panel5} alt="Brand 5" className="brands-logo" />
            </div>
          </Slider>
        </div>
      </div>
      <div className="brands-slider-container">
        <h3 className="brands-slider-subheading">Inverters</h3>
        <div className="brands-slider">
          <Slider {...settings} rtl={true}>
            <div>
              <img src={inverter1} alt="Brand 1" className="brands-logo" />
            </div>
            <div>
              <img src={inverter2} alt="Brand 2" className="brands-logo" />
            </div>
            <div>
              <img src={inverter3} alt="Brand 3" className="brands-logo" />
            </div>
            <div>
              <img src={inverter4} alt="Brand 4" className="brands-logo" />
            </div>
            <div>
              <img src={inverter5} alt="Brand 5" className="brands-logo" />
            </div>
            <div>
              <img src={inverter6} alt="Brand 6" className="brands-logo" />
            </div>
            <div>
              <img src={inverter7} alt="Brand 7" className="brands-logo" />
            </div>
            <div>
              <img src={inverter8} alt="Brand 8" className="brands-logo" />
            </div>
            <div>
              <img src={inverter9} alt="Brand 9" className="brands-logo" />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Brands;
