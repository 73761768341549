import React from 'react';
import './Services.css'; // Make sure to import the CSS file
import Heading from './Heading';
import service from '../Images/service.webp';
/* import RIcon from '../Images/R.png'; // Import the image
 */
export default function Services() {
  return (
    <>
      <Heading 
        heading="Services" 
        subheading="Our Sustainable Solar Solutions"  
        backgroundImage={service} 
        gradientOverlay="rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.304)"
      />
      <div className="services-container">
        <div className="service-box on-grid">
          <h2>On-Grid</h2>
          <p>An On-Grid or Grid-Tied system, is connected to the main electrical grid and uses grid power when available. It also sells excess energy back to the grid through net metering, but it does not have battery storage. This system requires approval from the grid connection authority and is suitable for areas with reliable grid power.</p>
          <div className="overlay-icon">
{/*             <img src={RIcon} alt="R Icon" />
 */}          </div>
        </div>
        <div className="service-box off-grid">
          <h2>Off-Grid</h2>
          <p>An Off-Grid system, also known as a Stand-Alone system, is not connected to the main electrical grid and relies on local power generation, such as solar or wind power. It requires battery storage for energy backup and is suitable for remote areas or where grid connection is not feasible. This system can be more expensive than On-Grid systems.</p>
          <div className="overlay-icon">
            {/* <img src={RIcon} alt="R Icon" /> */}
          </div>
        </div>
        <div className="service-box hybrid">
          <h2>Hybrid</h2>
          <p>A Hybrid system combines the features of On-Grid and Off-Grid systems, connected to the grid but also having battery storage. It can use grid power when available and switch to battery power during outages, selling excess energy back to the grid. It is suitable for areas with frequent power outages or those seeking energy independence.</p>
          <div className="overlay-icon">
{/*             <img src={RIcon} alt="R Icon" />
 */}          </div>
        </div>
      </div>
    </>
  );
}
