import React, { useState } from "react";
import emailjs from "emailjs-com";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bulb from "../Images/bulb.png";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faWhatsapp,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import "./ContactUs.css"; // Import the CSS file
import Heading from "./Heading";
import contact from "../Images/contact.webp";

export default function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let success = true;
    emailjs
      .send("contact_service", "contact_us", formData, "M3Ka6RyLg5SFCN3Cf")
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
      })
      .catch((err) => {
        console.log("FAILED...", err);
        success = false;
      });
    emailjs
      .send("contact_service", "confirm_contact", formData, "M3Ka6RyLg5SFCN3Cf")
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
      })
      .catch((err) => {
        console.log("FAILED...", err);
        success = false;
      });

    if (success) {
      alert("Your message has been sent successfully!");
      setFormData({ name: "", email: "", phone: "", message: "" }); // Reset form
    } else {
      alert("Error!");
    }
  };

  return (
    <>
      <Heading
        heading="Contact-Us"
        subheading="Book a Project!"
        backgroundImage={contact}
        gradientOverlay="rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.404)"
      />
      <div className="contact-us-page">
        <div className="contact-us-container">
          <div className="contact-us-form">
            <form onSubmit={handleSubmit}>
              <div className="contact-us-form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="contact-us-form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="contact-us-form-group">
                <label htmlFor="phone">Phone Number</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="contact-us-form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="5"
                  required
                ></textarea>
              </div>
              <button type="submit" className="contact-us-submit-button">
                Submit
              </button>
            </form>
          </div>
          <div className="contact-us-right">
            <div className="contact-info">
              <div className="contact-info-text">
              <h2 className="contact-heading">Contact Information</h2>
                <div className="contact-item">
                  <a href="tel:+923352223355" aria-label="Call us">
                    <FontAwesomeIcon icon={faPhone} className="contact-icon phone" />
                  </a>
                  <p>Feel free to call us at +92 335 222 3355 for any queries.</p>
                </div>
                <div className="contact-item">
                  <a href="info@greennsolar.com" aria-label="Email us">
                    <FontAwesomeIcon icon={faEnvelope} className="contact-icon mail" />
                  </a>
                  <p>Mail us at info@greennsolar.com for more information.</p>
                </div>
                <div className="contact-item">
                  <a href="https://wa.me/+923356166279" aria-label="WhatsApp us">
                    <FontAwesomeIcon icon={faWhatsapp} className="contact-icon ws" />
                  </a>
                  <p>Leave a message at +92 335 616 6279 to schedule an appointment.</p>
                </div>
                <div className="contact-item">
                  <a href="https://www.facebook.com/profile.php?id=100091609681406&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" aria-label="Follow us on Facebook">
                    <FontAwesomeIcon icon={faFacebook} className="contact-icon fb" />
                  </a>
                  <p>Like our page on Facebook!</p>
                </div>
                <div className="contact-item">
                  <a href="https://www.instagram.com/greennsolar/" target="_blank" rel="noopener noreferrer" aria-label="Follow us on Instagram">
                    <FontAwesomeIcon icon={faInstagram} className="contact-icon insta" />
                  </a>
                  <p>Follow us on Instagram!</p>
                </div>
              </div>
              <div className="contact-info-image">
                <img src={bulb} alt="Bulb Icon" className="bulb-image" />
              </div>
            </div>

            <div className="contact-us-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3404.051385384872!2d74.20003127560832!3d31.440252274250696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzHCsDI2JzI0LjkiTiA3NMKwMTInMDkuNCJF!5e0!3m2!1sen!2s!4v1725118113156!5m2!1sen!2s"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                title="Green N Solar"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
