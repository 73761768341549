import React, { useState } from "react";
import "./FAQ.css";

const FAQ = () => {
  const [expanded, setExpanded] = useState(null);

  const toggleExpand = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  const faqs = [
    {
      question: "What’s the difference between On-Grid & Off-Grid System?",
      answer:
        "On-Grid systems are connected to the public electricity grid and do not require battery storage. Off-Grid systems are not connected to the grid and require batteries to store energy.",
    },
    {
      question: "In how much time will I get my net metering done?",
      answer: "Net metering typically takes 30 to 60 days, depending on the local utility company.",
    },
    {
      question: "What are the Pros & Cons of On-Grid & Off-Grid System?",
      answer:
        "On-Grid Pros: Lower cost, no batteries required. Cons: No power during outages. Off-Grid Pros: Energy independence. Cons: Higher cost, battery maintenance required.",
    },
    {
      question: "How many Units are produced by a 1kW System on average?",
      answer: "A 1kW solar system typically produces 4 to 5 units per day on average, depending on location and weather conditions.",
    },
    {
      question: "What’s the scale of each panel which you use frequently?",
      answer: "Commonly used panels are around 1.6m x 1m in size, producing approximately 320-400W of power.",
    },
  ];

  return (
    <div className="faq-section">
      <h2 className="faq-section-title">
        Frequently Asked Questions <span className="bracket-text">(FAQs)</span>
      </h2>
      <div className="faq-section-container">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item-container">
            <div
              className="faq-question"
              onClick={() => toggleExpand(index)}
            >
              {faq.question}
              <span className={`faq-arrow ${expanded === index ? "faq-arrow-expanded" : ""}`}>
                &#9662;
              </span>
            </div>
            {expanded === index && (
              <div className="faq-answer">{faq.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
