import React from 'react';
import PropTypes from 'prop-types';
import './Heading.css'; // Import a CSS file for styling
import about from "../Images/about.webp"; // Image for goal


const Heading = ({ heading, subheading, backgroundImage, gradientOverlay }) => {
  const backgroundStyle = {
    backgroundImage: ` url(${about}),linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.45))`, // Black overlay with 50% opacity
    backgroundSize: 'cover', // 'cover' applies to both gradient and image
    backgroundPosition: 'center',
    backgroundBlendMode: 'overlay', // Ensures the gradient blends with the image
  };
  
  return (
    <div className="custom-heading-container" style={backgroundStyle}>
      <h1 className="custom-heading">{heading}</h1>
      {subheading && <h2 className="custom-subheading">{subheading}</h2>}
    </div>
  );
};

Heading.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  backgroundImage: PropTypes.string,
  gradientOverlay: PropTypes.string,
};

Heading.defaultProps = {
  subheading: '',
  backgroundImage: '/path/to/default-image.webp', // Default path to an image in the public folder
  gradientOverlay: 'rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.404)',
};

export default Heading;
