import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navbar.css";

export default function Navbar() {
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const navbarRef = useRef(null); // Ref to the navbar element

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
        if (expanded) {
          setExpanded(false); // Collapse navbar on scroll
        }
      } else {
        setScrolled(false);
      }
    };

    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setExpanded(false); // Collapse navbar if clicked outside
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("click", handleClickOutside); // Listen for clicks outside the navbar

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("click", handleClickOutside); // Clean up event listener
    };
  }, [expanded]);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top on link click
    if (expanded) {
      setExpanded(false); // Close the navbar if expanded
    }
  };

  const handleLogoClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top on logo click
    handleLinkClick(); // Close navbar if expanded
  };

  return (
    <nav
      ref={navbarRef} // Attach the ref to the navbar element
      className={`navbar navbar-expand-lg ${scrolled ? "scrolled" : ""} ${
        expanded ? "expanded" : ""
      }`}
    >
      <div className="container-fluid">
        {/* Logo with link to home */}
        <Link to="/home" onClick={handleLogoClick}>
          <img
            src="logo.png"
            alt="Logo"
            className="d-inline-block align-top logo"
          />
        </Link>
        {/* Toggle button for mobile view */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={handleToggle}
          aria-controls="navbarSupportedContent"
          aria-expanded={expanded}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        {/* Navbar links */}
        <div
          className={`collapse navbar-collapse ${expanded ? "show" : ""}`}
          id="navbarSupportedContent"
        >
          <div className="navbar-links">
            <ul className="navbar-nav">
              {["home", "projects", "services", "about"].map((page) => (
                <li className="nav-item" key={page}>
                  <Link
                    className={`nav-link ${
                      location.pathname === `/${page}` ? "active" : ""
                    }`}
                    to={`/${page}`}
                    onClick={handleLinkClick} // Close navbar and scroll to top on link click
                  >
                    {page.charAt(0).toUpperCase() + page.slice(1)}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <li className="nav-item d-flex align-items-center">
            <div className="contact-button-container">
              <Link
                className={`nav-link contact-button ${
                  location.pathname === "/contactus" ? "active" : ""
                }`}
                to="/contactus"
                onClick={handleLinkClick} // Close navbar and scroll to top on link click
              >
                Contact Us
              </Link>
            </div>
          </li>
        </div>
      </div>
    </nav>
  );
}
