import React from "react";
import "./Home.css";
import FAQ from "./FAQ";
import CarouselComponent from "./Carousel";
import Brands from "./Brands";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Hero from "./Hero";


const Home = () => {
  return (
    <div className="container-fluid p-0">
      <Hero/>
      <CarouselComponent />
      <Brands />
      <FAQ />
    </div>

  );
};

export default Home;
