import React from "react";
import "./Hero.css";
import solarImage from '../Images/home.webp';

const Hero = () => {
  return (
    <div className="hero-container">
      <img src={solarImage} alt="Solar Panels" className="background-image" />
      <div className="hero-content">
        <h2 className="hero-heading">
          <span className="highlight">Empowering</span> <br />
          Pakistan's Future: <br />
          <span className="highlight">Illuminate</span> Your World <br />
          with Our <span className="highlight">Solar Panel</span> <br />
          Services!
        </h2>
      </div>
    </div>
  );
};

export default Hero;
