// src/components/WhatsAppWidget.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './WhatsAppWidget.css';

const WhatsAppWidget = () => {
  const openWhatsApp = () => {
    const phoneNumber = '+923356166279'; // Replace with your WhatsApp number
    const message = encodeURIComponent('Hello Green n Solar!');
    window.open(`https://wa.me/${phoneNumber}?text=${message}`, '_blank');
  };

  return (
    <div className="whatsapp-widget" onClick={openWhatsApp}>
      <FontAwesomeIcon icon={faWhatsapp} className="whatsapp-icon" />
    </div>
  );
};

export default WhatsAppWidget;
