// src/App.js
import './App.css';
import About from './components/About';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import Home from './components/Home';
import Navbar from "./components/Navbar";
import Projects from './components/Projects';
import Services from './components/Services';
import ScrollToTopButton from './components/ScrollToTopButton'; // Import the scroll-to-top button
import WhatsAppWidget from './components/WhatsAppWidget'; // Import the WhatsApp widget

import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/home" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/projects" element={<Projects />}></Route>
          <Route path="/services" element={<Services />}></Route>
          <Route path="/contactus" element={<ContactUs />}></Route>
        </Routes>
        <ScrollToTopButton />
        <WhatsAppWidget /> {/* WhatsApp widget appears beneath the scroll-to-top button */}
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
