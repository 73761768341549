import React from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import "./About.css";
import aboutImage1 from "../Images/goal.webp"; // Image for mission
import aboutImage2 from "../Images/mission.jpg"; // Image for goal
import about from "../Images/about.webp"; // Image for goal
import ownerImage from "../Images/wahab1.webp";

import Heading from "./Heading";

export const About = () => {
  // Intersection observer for the statistics section
  const { ref: statsRef, inView: isStatsInView } = useInView({
    triggerOnce: true, // Trigger only once when the element comes into view
  });

  return (
    <>
      <Heading
        heading="About"
        subheading="Get to know our story"
        backgroundImage={about} // Use the imported image
        gradientOverlay="rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.464)" // Black gradient overlay
      />
      <div className="about-container">
        <div className="about-content">
          <div className="mission-goal-section">
            <img
              src={aboutImage1}
              alt="Our Mission"
              className="section-image-left"
            />
            <div className="text-content">
              <h3>Our Mission</h3>
              <p>
                Our mission is to provide high-quality solar solutions that not
                only meet but exceed our clients' expectations. We are committed
                to delivering innovative and sustainable energy solutions that
                enhance the quality of life while promoting environmental
                stewardship.
              </p>
            </div>
          </div>
          <div className="additional-text">
            <p>
              <h3>Your trusted solar installation partner:</h3> We specialize in
              expert solar installation services tailored to meet your specific
              needs. From seamless On-Grid installations to Off-Grid solutions
              for remote locations, and versatile Hybrid systems, we're your
              trusted partner in harnessing the power of solar energy for your
              home or business.
            </p>
          </div>
          <div className="statistics" ref={statsRef}>
            <div className="stat-item">
              <h4>{isStatsInView && <CountUp end={80} duration={4} />}+</h4>
              <p>Project Completed</p>
            </div>
            <div className="stat-item">
              <h4>{isStatsInView && <CountUp end={70} duration={4} />}+</h4>
              <p>Satisfied Customers</p>
            </div>
            <div className="stat-item">
              <h4>{isStatsInView && <CountUp end={200} duration={4} />}+</h4>
              <p>Quotations Shared</p>
            </div>
          </div>
          <div className="mission-goal-section goal-section">
            <div className="text-content">
              <h3>Our Goal</h3>
              <p>
                Our goal is to lead the way in solar innovation and
                sustainability, offering cutting-edge technology and exceptional
                service to our clients. We aim to make solar energy accessible
                and affordable for everyone, contributing to a greener future
                for generations to come.
              </p>
            </div>
            <img
              src={aboutImage2}
              alt="Our Goal"
              className="section-image-right"
            />
          </div>
          <div className="owner-card">
            <div className="owner-image-container">
              <img src={ownerImage} alt="Owner" className="owner-image" />
            </div>
            <div className="owner-details">
              <h4 className="owner-title">Kh Abdul Wahab</h4>
              <p className="owner-designation">
                <i>CEO at Green N Solar</i>
              </p>
              <p className="owner-description">
                Kh Abdul Wahab, founder of Green N
                Solar, embodies the spirit of innovation and dedication.He launched the
                company to make solar energy more accessible and affordable. His
                commitment to combining advanced technology with sustainable
                practices has quickly established Green N Solar as a leader in
                the industry, showcasing his passion for environmental
                stewardship and entrepreneurial excellence.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
