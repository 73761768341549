import React, { useEffect, useState } from "react";
import "./Carousel.css";
import slide1 from "../Images/slide1.webp"; // Replace with your image path
import slide2 from "../Images/slide2.webp"; // Replace with your image path

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [slide1, slide2];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <div className="carousel">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`carousel-slide ${index === currentSlide ? "active" : ""}`}
          style={{ backgroundImage: `url(${slide})` }}
        />
      ))}
      <div className="carousel-dots">
        {slides.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentSlide ? "active" : ""}`}
            onClick={() => setCurrentSlide(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};
export default Carousel;
