import React, { useState } from 'react';
import './ProjectItem.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const ProjectItem = ({ title, kv, description, mainImage, additionalMedia }) => {
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [lastTap, setLastTap] = useState(0);

  const handleDoubleTap = (media) => {
    const currentTime = new Date().getTime();
    const tapLength = currentTime - lastTap;

    if (tapLength < 300 && tapLength > 0) {
      setSelectedMedia(media);
    }

    setLastTap(currentTime);
  };

  const closeModal = () => {
    setSelectedMedia(null);
  };

  const mediaItems = [
    { type: 'image', src: mainImage },
    ...additionalMedia,
  ];

  const settings = {
    dots: true, // Enable dots
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: i => (
      <div className="images-dot"></div> // Customize the dot element
    ),
    appendDots: dots => (
      <div className="dots-container">
        <ul>{dots}</ul>
      </div>
    ),
  };

  return (
    <div className="project-item">
      <Slider {...settings} className="media-carousel">
        {mediaItems.map((media, index) => (
          media.type === 'image' ? (
            <div key={index} className="media-carousel-item">
              <img
                src={media.src}
                alt={`Media ${index + 1}`}
                className="media-carousel-media"
                onClick={() => handleDoubleTap(media)}
              />
            </div>
          ) : (
            <div key={index} className="media-carousel-item">
              <video
                className="media-carousel-media"
                loop
                autoPlay
                muted
                playsInline
                controls={false}
                onClick={() => handleDoubleTap(media)}
              >
                <source src={media.src} type="video/webm" />
                Your browser does not support the video tag.
              </video>
            </div>
          )
        ))}
      </Slider>
      {selectedMedia && (
        <div className="modal" onClick={closeModal}>
          <span className="close">&times;</span>
          {selectedMedia.type === 'image' ? (
            <img src={selectedMedia.src} alt="Selected" className="modal-media" />
          ) : (
            <video className="modal-media" loop autoPlay muted playsInline controls={false}>
              <source src={selectedMedia.src} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      )}
      <div className="overlay">
        <div className="overlay-content">
          <h3 className="title">{title}</h3>
          <p className="kv">{kv}</p>
          <p className="description">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default ProjectItem;
