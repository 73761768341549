import React from "react";
import ProjectItem from "./ProjectItem"; // Import the ProjectItem component
import "./Projects.css"; // Import the custom CSS file
import project from '../Images/project.webp'

import main1 from "../Images/Projects/Project (1)/main1.webp";
import main2 from "../Images/Projects/Project (2)/main2.webp";
import main3 from "../Images/Projects/Project (3)/main3.webp";
import main4 from "../Images/Projects/Project (4)/main4.webp";
import main5 from "../Images/Projects/Project (5)/main5.webp";
import main6 from "../Images/Projects/Project (6)/main6.webp";
import main7 from "../Images/Projects/Project (7)/main7.webp";
import main8 from "../Images/Projects/Project (8)/main8.webp";
import main9 from "../Images/Projects/Project (9)/main9.webp";
import main10 from "../Images/Projects/Project (10)/main10.webp";

import p1i1 from "../Images/Projects/Project (1)/post (1).webp";
import p1i2 from "../Images/Projects/Project (1)/post (2).webp";
import p1i3 from "../Images/Projects/Project (1)/post (3).webp";
import p1v4 from "../Images/Projects/Project (1)/post (4).webm";
import p1v5 from "../Images/Projects/Project (1)/post (5).webm";

import p2v1 from "../Images/Projects/Project (2)/post (1).mov";
import p2v2 from "../Images/Projects/Project (2)/post (2).mov";
import p2v3 from "../Images/Projects/Project (2)/post (3).mov";

import p3i1 from "../Images/Projects/Project (3)/post (1).webp";
import p3i2 from "../Images/Projects/Project (3)/post (2).webp";
import p3i3 from "../Images/Projects/Project (3)/post (3).webp";
import p3v1 from "../Images/Projects/Project (3)/post (4).mov";

import p4v1 from "../Images/Projects/Project (4)/post (1).mov";
import p4v2 from "../Images/Projects/Project (4)/post (2).mov";

import p5v1 from "../Images/Projects/Project (5)/post (1).webm";
import p5v2 from "../Images/Projects/Project (5)/post (2).webm";
import p5v3 from "../Images/Projects/Project (5)/post (3).webm";

import p6i1 from "../Images/Projects/Project (6)/post (1).webp";
import p6i2 from "../Images/Projects/Project (6)/post (2).webp";
import p6i3 from "../Images/Projects/Project (6)/post (3).webp";
import p6i4 from "../Images/Projects/Project (6)/post (4).webp";
import p6i5 from "../Images/Projects/Project (6)/post (5).webp";

import p7i1 from "../Images/Projects/Project (7)/post (1).webp";
import p7i2 from "../Images/Projects/Project (7)/post (2).webp";
import p7i3 from "../Images/Projects/Project (7)/post (3).webp";
import p7i4 from "../Images/Projects/Project (7)/post (4).webp";
import p7i5 from "../Images/Projects/Project (7)/post (5).webp";

import p8i1 from "../Images/Projects/Project (8)/post (1).webp";
import p8v1 from "../Images/Projects/Project (8)/post (1).webm";
import p8v2 from "../Images/Projects/Project (8)/post (2).webm";

import p9i1 from "../Images/Projects/Project (9)/post (1).webp";
import p9i2 from "../Images/Projects/Project (9)/post (2).webp";

import p10i1 from "../Images/Projects/Project (10)/post (1).webp";
import p10i2 from "../Images/Projects/Project (10)/post (2).webp";
import p10i3 from "../Images/Projects/Project (10)/post (3).webp";
import p10i4 from "../Images/Projects/Project (10)/post (4).webp";
import Heading from "./Heading";

const projects = [
  {
    title: "Project Valencia",
    kv: "20 kW",
    description: "",
    mainImage: main8,
    additionalMedia: [
      { type: "image", src: p8i1 },
      { type: "video", src: p8v1 }, 
      { type: "video", src: p8v2 }
    ],
  },
  {
    title: "Project Gulshan-e-Ravi",
    kv: "10 kW On-Grid",
    description: "",
    mainImage: main3,
    additionalMedia: [
      { type: "image", src: p3i1 },
      { type: "image", src: p3i2 },
      { type: "image", src: p3i3 },
      { type: "video", src: p3v1 },
    ],
  },
   
  {
    title: "Project Canal City 2.0",
    kv: "10 kW",
    description: "",
    mainImage: main4,
    additionalMedia: [
      { type: "video", src: p4v1 },
      { type: "video", src: p4v2 },
    ],
  },
  {
    title: "Project Johar Town",
    kv: "10 kW On-Grid",
    description: "",
    mainImage: main2,
    additionalMedia: [
      { type: "video", src: p2v1 },
      { type: "video", src: p2v2 },
      { type: "video", src: p2v3 },
    ],
  },
  {
    title: "Project Jasmine Lane",
    kv: "10 kW",
    description: "",
    mainImage: main5,
    additionalMedia: [
      { type: "video", src: p5v1 },
      { type: "video", src: p5v2 },

      { type: "video", src: p5v3 },
    ],
  },
  {
    title: "Project Model Town",
    kv: "12 kW On-Grid System",
    description: "",
    mainImage: main6,
    additionalMedia: [
      { type: "image", src: p6i1 },
      { type: "image", src: p6i2 },
      { type: "image", src: p6i3 },
      { type: "image", src: p6i4 },
      { type: "image", src: p6i5 },
    ],
  },
  {
    title: "Project Canal City 1.0",
    kv: "10 kW Hybrid System",
    description: "",
    mainImage: main1,
    additionalMedia: [
      { type: "image", src: p1i1 },
      { type: "image", src: p1i2 },
      { type: "image", src: p1i3 },
      { type: "video", src: p1v4 },
      { type: "video", src: p1v5 },
    ],
  },
  {
    title: "Project Iqbal Town",
    kv: "15 kW",
    description: "",
    mainImage: main7,
    additionalMedia: [
      { type: "image", src: p7i1 },
      { type: "image", src: p7i2 },
      { type: "image", src: p7i3 },
      { type: "image", src: p7i4 },
      { type: "image", src: p7i5 },
    ],
  },
  {
    title: "Project Johar Town 2.0",
    kv: "1 kW",
    description: "",
    mainImage: main9,
    additionalMedia: [
      { type: "image", src: p9i1 },
      { type: "image", src: p9i2 },
    ],
  },
  {
    title: "Project 10",
    kv: "6 kW",
    description: "",
    mainImage: main10,
    additionalMedia: [
      { type: "image", src: p10i1 },
      { type: "image", src: p10i2 },
      { type: "image", src: p10i3 },
      { type: "image", src: p10i4 },
    ],
  },
];

export default function Project() {
  return (
    <><Heading heading="Projects" subheading="Showcasing our recently completed work"  backgroundImage={project} // Use the imported image
       gradientOverlay="rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.304)" />
    <div className="project-container">
      
      <div className="container">
        <div className="row my-3">
          {projects.map((project, index) => (
            <div className="col-md-4 mb-4 project-card" key={index}>
              <ProjectItem
                title={project.title}
                kv={project.kv}
                description={project.description}
                mainImage={project.mainImage}
                additionalMedia={project.additionalMedia}
              />
            </div>
          ))}
        </div>
      </div>
    </div></>
  );
}
